.OptionList {
  margin: auto;
}
.OptionList .confirmation {
  display: inline;
  opacity: 0.5;
  text-decoration: underline;
}
.OptionList .element {
    margin-bottom: 2rem;;
}

@keyframes loading {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.OptionList .confirmation .loadingAnimated {
  animation: loading 2s ease-in-out infinite;
}

.OptionList .header {
  font-size: 1.3em;
  margin-bottom: 2rem;
  font-weight: bold; /* Make the header bold */
  color: #1F1F1F;
  text-transform: uppercase; /* Convert text to uppercase */
}
.OptionList .header::after {
  content: '';
  display: block;
  width: 15rem;
  height: 2px; 
  background-color: #1F1F1F; 
  margin-top: 0.4rem; 
  opacity: 0.7;
}
