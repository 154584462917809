.CardContainer {
    padding-top: 1em;
    padding-left: 4%;
    padding-right: 4%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-column-gap: 2%;
    grid-row-gap: 8%;
    justify-content: center;
}

.CardContainer > * {
    margin: 0 auto;
}

.NoUserText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    padding-top: 1em;
    padding-left: 4%;
    padding-right: 4%;
    color: lightgrey;
}

@media only screen and (min-width: 1500px) {
    .CardContainer {
        grid-template-columns: repeat(4, 21.25%);
    }
}

@media only screen and (min-width: 1900px) {
    .CardContainer {
        grid-template-columns: repeat(5, 16%);
    }
}
