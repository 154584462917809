.FilterInput {
  margin-bottom: 1em;
}

.MachineMenu {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
}

.MachineMenuEntry {
  cursor: pointer;
  margin: .3em auto;
  text-align: left;
  padding: .5em;
  background-color: white;
  border-radius: .5em;
  box-shadow: 0 0.2em 0.3em 0 gainsboro;
  user-select: none;
}

.MachineMenuEntry > h3 {
  font-size: 2.25em;
}

.MachineMenuEntry li:last-child > .MachineMenuEntry {
  margin-bottom: 1em;
}

.MenuContent {
  overflow-y: auto;
}

.MenuContent > ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.MenuDirections > p {
  text-align: center;
}

.selected {
  background-color: rgb(224, 58, 62, 0.9);
}

.selectable.unselected:hover {
  background-color: black;
  color: white;
  box-shadow: none;
  font-weight: bold;
}

.unselectable {
  background-color: gray;
}

@media only screen and (min-width: 640px) {
  .MenuContent > ul {
      grid-template-columns: 47.5% 47.5%;
      grid-column-gap: 5%;
  }
}

@media only screen and (min-width: 981px) {
  .MenuContent > ul {
      grid-template-columns: 30% 30% 30%;
  }
}
