.SliderBranch {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
}

.SliderBranchChoice {
    width: 10em;
    height: 15em; 
    background-color: white;
    box-shadow: 0 0.5em 0.5em 0 gainsboro;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1em;
    overflow: hidden;
    margin: 1em;
    user-select: none;
    border-radius: 1em;
}

.SliderBranchChoice:hover {
    filter: invert(1);
}

.SliderBranchChoice > img {
    width: 50%;
    margin: auto;
    pointer-events: none;
}

.SliderBranchChoice > span {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 1em;
    line-height: 1.25em;
    margin-top: .25em;
}

.Disabled {
    pointer-events: none;
    opacity: 0.4;
}