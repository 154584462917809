.submission-container {
    text-align: center;
    width: 100%;
}

.submission-message {
    font-size: 1.2em;
}

.check-icon {
    color: green;
    font-size: 3em;
}

.error-icon {
    color: red;
    font-size: 3em;
}

.continue-button {
    display: inline-block;
    width: fit-content;
    margin-top: -1em;
}