#app{
    height: 100vh
}

body {
    overflow-y: scroll;
}

/*Allows the badge-machine-type table to scroll*/
.dataTableWrapper {
    height: 500px;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
}