.Slider {
    position: absolute;
    width: 100%;
    background-color: #f9f9f9;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 1em 1em 0 0;
    box-shadow: 0 .5em .5em .5em;
    z-index: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Slider:nth-child(1) {
    border-radius: 0;
}

.Slider > nav {
    background-color: white;
    text-align: center;
    font-size: 1.8em;
    line-height: 1.8em;
    user-select: none;
}

.Slider > nav:hover {
    filter: brightness(.95);
}

.Slider > nav > img {
    height: 1.8em;
    position: absolute;
    left: 1em;
    filter: grayscale(100) contrast(2000%);
}

.SliderContentContainer {
    font-size: 1.2em;
    padding: 2% 5% 5em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contentTitle {
    font-size: 1.5em;
    text-align: center;
}

@media (pointer:none), (pointer:coarse) {
    .Slider > nav {
        font-size: .5em;
        line-height: .5em;
        height: .5em;
        color: green;
    }

    .SliderContentContainer {
        font-size: 1.5em;
        overflow-y: auto;
        margin: 0 3em;
    }
}
