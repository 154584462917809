.mini-button {
    line-height: 1.6em;
    font-size: 2.5em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 2.5em;
    position: fixed;
    bottom: 0.75em;
    z-index: 1;
    text-align: center;
    color: white;
    animation: slideFromBottom .5s;
}

.mini-button-kill {
    animation: slideToRight .5s forwards;
}

.mini-button:hover {
    filter: brightness(1.25);
    box-shadow: none;
    cursor: pointer;
    box-shadow: 0px 10px 5px 0 silver;
}

.mini-button-add {
    right: 0.75em;
    background-color: cornflowerblue;
    box-shadow: 0px 10px 5px 0 lightgray;
}

.mini-button-delete {
    left: 0.75em;
    background-color: red;
    box-shadow: 0px 10px 5px 0 lightgray;
}
